.tableCell {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: var(--chakra-sizes-full);
  overflow: hidden;
  white-space: nowrap;

  &.noPointerEvents {
    pointer-events: none;
  }
}

.tableCellBox {
  flex-shrink: 0;
  font-weight: var(--chakra-fontWeights-medium);
  line-height: var(--chakra-lineHeights-6);
  padding-left: var(--chakra-space-2);
  padding-right: var(--chakra-space-2);
  position: relative;
  user-select: none;

  &.isExpandable {
    padding-left: 0;
  }

  &.isSelectable {
    cursor: pointer;
  }

  &.isSticky {
    position: sticky;
  }

  &.isGroupHeading {
    font-weight: var(--chakra-fontWeights-semibold);
  }

  &.userLocalPrimary {
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 4px,
      color(from #81caff srgb r g b / 0.15) 4px,
      color(from #81caff srgb r g b / 0.15) 6px
    );
  }

  &.userLocalSecondary {
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 5px,
      color(from var(--chakra-colors-gray-600) srgb r g b / 0.1) 4px,
      color(from var(--chakra-colors-gray-600) srgb r g b / 0.1) 6px
    );
  }
}
