.timestamp {
  font-size: var(--chakra-fontSizes-xs);
}

.actuals {
  color: var(--chakra-colors-table-row);
}

.forecast {
  color: var(--chakra-colors-table-forecast);
}

.new {
  color: var(--chakra-colors-table-row);
}

.error {
  color: var(--chakra-colors-error-500);
}

.number {
  font-size: var(--chakra-fontSizes-xs);
  font-style: normal;
}

.italic {
  font-style: italic;
}

.placeholder {
  color: var(--chakra-colors-text-placeholder);
}

.zero {
  opacity: 0.5;
}
