.customTooltip {
  background-color: var(--chakra-colors-tooltip-background);
  border-radius: var(--chakra-radii-md);
  color: var(--chakra-colors-tooltip-color);
  font-weight: var(--chakra-fontWeights-medium);
  max-width: 24rem;
  padding: var(--chakra-space-1) var(--chakra-space-2);
  pointer-events: none;

  &.interactive {
    pointer-events: auto;
  }
}
