.plan-impacted {
  color: var(--chakra-colors-table-forecast);
  text-decoration: underline dashed;
  text-decoration-color: var(--chakra-colors-table-forecast);
  text-underline-offset: var(--chakra-sizes-1);
}

.actual-hardcoded {
  color: var(--chakra-colors-table-row);
  text-decoration: underline dashed;
  text-decoration-color: var(--chakra-colors-table-row);
  text-underline-offset: var(--chakra-sizes-1);
}

.actuals {
  color: var(--chakra-colors-table-row);
}

.forecast {
  color: var(--chakra-colors-table-forecast);
}

.error {
  color: var(--chakra-colors-error-500);
}

.aligned-left {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.aligned-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.absolute {
  position: absolute;
}

.padded {
  padding-right: 8px;
}

.good {
  color: var(--chakra-colors-success-700);
}

.bad {
  color: var(--chakra-colors-error-500);
}

.number {
  font-size: var(--chakra-fontSizes-xs);
  font-style: normal;
}

.zero {
  opacity: 0.5;
}
