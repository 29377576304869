.displayCell {
  align-items: center;
  display: flex;
  height: var(--chakra-sizes-full);
  overflow: hidden;
  text-decoration: inherit;
  width: var(--chakra-sizes-full);

  &.isNumber {
    font-feature-settings:
      'tnum' on,
      'lnum' on;
    padding-left: var(--chakra-space-2);
    padding-right: var(--chakra-space-2);

    &.isLeftAligned {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.isTimestamp {
    padding-left: var(--chakra-space-2);
    padding-right: var(--chakra-space-2);
  }
}
