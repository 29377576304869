.cellBorder {
  pointer-events: none;
  position: absolute;

  &.copiedAndNotSelected {
    border-color: var(--chakra-colors-selection-500);
    border-style: dashed;
    border-width: 2px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    z-index: 5;
  }

  &.active {
    border-color: var(--chakra-colors-selection-500);
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    left: -1px;
    right: 0;
    top: -1px;
    z-index: 6;

    &.isCopiedAndSelected {
      border-width: 1px;
    }
  }

  &.selected {
    border-color: var(--chakra-colors-selection-500);
    border-style: solid;
    border-width: 1px;
    bottom: 0;
    left: -1px;
    right: 0;
    top: -1px;
    z-index: 6;

    &.isAboveCellSelected {
      border-top-color: transparent;
    }

    &.isBelowCellSelected {
      border-bottom-color: var(--chakra-colors-table-border);
    }

    &.isLeftCellSelected {
      border-left-color: transparent;
    }

    &.isRightCellSelected.hasSolidRightBorder {
      border-right-color: var(--chakra-colors-table-headerBorder);
    }

    &.isRightCellSelected:not(.hasSolidRightBorder) {
      border-right-color: var(--chakra-colors-table-border);
    }
  }

  &.default {
    border-color: var(--chakra-colors-table-border);
    border-style: solid;
    border-width: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;

    &.hasSolidRightBorder {
      border-right-color: var(--chakra-colors-table-headerBorder) !important;
      border-right-style: solid;
    }

    &.hasSolidBottomBorder {
      border-bottom-color: var(--chakra-colors-table-headerBorder);
      border-bottom-style: solid;
    }

    &.hasRightBorder {
      border-right-width: 1px;
    }

    &.hasBottomBorder {
      border-bottom-width: 1px;
    }
  }
}
