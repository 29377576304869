.rowActionsContainer {
  align-items: center;
  display: flex;
  justify-self: flex-end;

  &.notVisible {
    display: none;

    [role='row']:hover &,
    [role='row'].hover & {
      display: flex;
    }
  }

  .rowActions {
    display: none;
    filter: brightness(100%);

    &.isHighlighed {
      filter: brightness(20%);
    }

    [role='row']:hover &,
    [role='row'].hover & {
      display: flex;
    }
  }

  .alwaysVisibleChildrenContainer {
    display: flex;
  }
}
