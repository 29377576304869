.timeSeriesCellWrapper {
  cursor: pointer;
  flex-shrink: 0;
  font-size: var(--chakra-fontSizes-xs);
  font-weight: var(--chakra-fontWeights-medium);
  line-height: var(--chakra-lineHeights-6);
  position: relative;
  text-decoration-style: dashed;
  text-underline-offset: var(--chakra-sizes-1);
  user-select: none;
}

.timeSeriesCellWrapperOverflowHidden {
  height: var(--chakra-sizes-full);
  overflow-y: hidden;
  text-decoration: inherit;
  width: var(--chakra-sizes-full);
}
