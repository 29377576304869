@import '../../public/fonts/style.css';

body {
  background: var(--chakra-colors-background);
  font-display: swap;
  font-family:
    InterVariable,
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    Arial,
    'Helvetica Neue',
    'Segoe UI',
    Roboto,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  overflow: hidden;
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
}

.visx-tooltip {
  z-index: 10000;
}

:focus-visible {
  outline: none;
}
