.container {
  align-items: center;
  display: flex;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
}

.arrow {
  align-items: center;
  display: flex;
  font-size: var(--chakra-fontSizes-xs);
  justify-content: center;
  min-width: 1rem;
}
