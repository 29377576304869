.reorderable {
  display: flex;
  height: var(--chakra-sizes-full);
  position: relative;
  width: var(--chakra-sizes-full);

  &.isEnabled {
    cursor: grab;
  }
}
