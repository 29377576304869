// see https://github.com/missive/emoji-mart/blob/main/packages/emoji-mart-website/example-custom-styles.html

em-emoji-picker {
  --border-radius: 0;
  --category-icon-size: 14px;
  --font-size: 12px;
  --rgb-accent: 24, 160, 251;
  --shadow: 0;
  max-height: 350px;
}
