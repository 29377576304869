// N.B. we don't use modules here because the class names are significant in Draft
/* stylelint-disable selector-no-qualifying-type, selector-max-compound-selectors */
.formulaEditor {
  &.collapseLeadingSpace {
    .public-DraftStyleDefault-block {
      span[data-offset-key]:first-child {
        display: inline-block;
        left: -1px;
        position: relative;
        top: 1px;
        width: 1px;
      }
    }
  }

  &.collapseTrailingSpace {
    .public-DraftStyleDefault-block {
      span[data-offset-key]:last-child {
        display: inline-block;
        left: -1px;
        position: relative;
        top: 1px;
        width: 1px;
      }
    }
  }

  .public-DraftStyleDefault-block {
    span[data-offset-key]:first-child {
      margin-left: var(--chakra-space-3);
    }

    > span[data-offset-key] {
      span[data-text] {
        font-family: var(--chakra-fonts-mono);
        line-height: var(--chakra-lineHeights-base);
      }
    }
  }

  &.noBorder .public-DraftEditor-content {
    border: 0;
  }

  &.condenseHeight {
    .public-DraftEditor-content {
      padding-bottom: 2px;
      padding-top: 2px;
    }
  }

  &.error {
    .public-DraftEditor-content {
      &:focus {
        border-color: var(--chakra-colors-failure);
      }
    }
  }

  .DraftEditor-editorContainer {
    border: 0;
  }

  &.isSafari.empty .public-DraftEditor-content {
    padding-left: 1.25rem;
  }

  .public-DraftEditor-content {
    border-color: var(--chakra-colors-gray-200);
    border-radius: var(--chakra-radii-md);
    border-width: 2px;
    padding: var(--chakra-space-2);

    &:focus {
      border-color: var(--chakra-colors-selection-500);
    }
  }

  &.omitHorizontalPadding .public-DraftEditor-content {
    padding-left: 0;
    padding-right: 0;
  }

  grammarly-extension {
    display: none;
  }
}
