.timeseriesCell {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}

.displayValue {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
}
