/* stylelint-disable value-no-vendor-prefix */
.displayValue {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noDisplayValue {
  height: var(--chakra-sizes-full);
  width: var(--chakra-sizes-full);
}
