.rowContainer {
  display: flex;
  flex-grow: 1;
  position: relative;
  width: fit-content;

  &.isFirstRow .cellBorder {
    top: 0;
  }
}
