.inheritedFormulaPill {
  align-items: center;
  align-self: center;
  background-color: var(--chakra-colors-gr);
  border-color: var(--chakra-colors-gray-100);
  border-radius: var(--chakra-radii-lg);
  border-width: 1px;
  color: var(--chakra-colors-gray-600);
  cursor: default;
  display: flex;
  height: fit-content;
  max-width: 28rem;
  padding-bottom: var(--chakra-space-px);
  padding-right: var(--chakra-space-1);
  padding-top: var(--chakra-space-px);

  // Show hover style unless you're hovering over the open details modal button
  &:hover:not(:has(.openDetailsModalButton:hover)) {
    background-color: var(--chakra-colors-gray-100);
  }
}

// This is necessary to set the hover state of the button to the parent div.
/* stylelint-disable block-no-empty */
.openDetailsModalButton {
}
/* stylelint-enable block-no-empty */
