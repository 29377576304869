.numericDisplayCell {
  overflow: hidden;
  padding-right: 1px;
  text-align: left;
  text-decoration: inherit;
  white-space: nowrap;
  width: var(--chakra-sizes-full);
  word-break: break-all;

  &.alignRight {
    text-align: right;
  }

  &.zero {
    opacity: 0.5;
  }
}

.displayValue {
  display: inline;
}

.noDisplayValue {
  height: var(--chakra-sizes-full);
  width: var(--chakra-sizes-full);
}
