/* Basic editor styles */
.ProseMirror {
  background-color: var(--chakra-colors-surface-background);
  color: var(--chakra-colors-text-primary);
  max-width: 45rem;
  padding-bottom: var(--chakra-space-2);

  ul,
  ol {
    padding: 0 var(--chakra-space-4);
  }

  .task-list {
    list-style: none;
    padding: 0;

    li {
      display: flex;

      /* stylelint-disable selector-max-compound-selectors */
      label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      div {
        flex: 1 1 auto;
      }

      p {
        margin: 0;
      }
      /* stylelint-enable selector-max-compound-selectors */
    }
  }

  a {
    color: var(--chakra-colors-selection-500);
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.2s;
  }

  a:hover,
  a:focus {
    color: var(--chakra-colors-selection-600);
  }

  p,
  li::marker {
    font-weight: var(--chakra-fontWeights-medium);
  }

  h1,
  h2,
  h3 {
    font-weight: var(--chakra-fontWeights-bold);
  }

  h1 {
    font-size: var(--chakra-fontSizes-xl);
    line-height: var(--chakra-fontSizes-3xl);
    padding: 24px 0 6px;
  }

  h2 {
    font-size: var(--chakra-fontSizes-lg);
    line-height: var(--chakra-fontSizes-2xl);
    padding: 18px 0 4px;
  }

  h3 {
    padding: 12px 0 4px;
  }

  p,
  h3,
  li::marker {
    font-size: var(--chakra-fontSizes-sm);
    line-height: 1.5;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    border-radius: 0.5rem;
    color: #fff;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }

  blockquote {
    border-left: 2px solid rgba(#0d0d0d, 0.1);
    padding-left: 1rem;
  }

  hr {
    border-top: 2px solid var(--chakra-colors-border-light);
    border-width: 0;
    margin: 2rem 0;
  }

  .mention {
    display: inline;
  }

  .is-editor-empty:first-child::before {
    color: var(--chakra-colors-text-placeholder);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}

// N.B. we set `dropcursor: false` in the editor options, but in some cases it looks like
// the cursor still is visible. This is a workaround to hide it.
.prosemirror-dropcursor-inline,
.prosemirror-dropcursor-block {
  background-color: transparent !important;
  width: 0 !important;
}

.focus-visible {
  outline: 0;
}
