// inspired by https://github.com/missive/emoji-mart/blob/16978d04a766eec6455e2e8bb21cd8dc0b3c7436/packages/emoji-mart/src/components/Picker/PickerStyles.scss#L31
.scroll {
  flex-grow: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0;

  &::-webkit-scrollbar {
    height: 16px;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border: 0;
  }

  &::-webkit-scrollbar-button {
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid #fff;
    border-radius: 8px;
    min-height: 20%;
    min-height: 65px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
